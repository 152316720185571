* {
  margin: 0;
  padding: 0;
  font-family: "Unbounded", sans-serif;
  overflow: hidden;
}
.white-bg {
  background-color: white;
}

.main-bg {
  background: rgb(13, 10, 14);
  background: linear-gradient(90deg, (black) 0%, rgba(112, 89, 253, 1) 100%);
}

p {
  margin: 12px;
  font-weight: 600;
}
.current-user-row {
  background: linear-gradient(
    to bottom,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );

  color: white;
}
.white {
  color: white;
}

.icon-footer {
  width: 24px;
}
.normal-users-row {
  background-color: #000; /* Цвет фона для обычных пользователей */
  color: white;
}
.card-community {
  background-color: #ffffff1a;
  width: 90%;
  margin: 10 auto;
  padding: 12px;
  padding-top: 24px;
  border-radius: 30px;
}
.footer-container {
  display: flex;
  justify-content: space-evenly;
  padding: 12px;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
}
.btn-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flex-col-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.icon-wrapper {
  padding: 8px;
  border-radius: 50px;
  /* background-color: #e6f7ff; */
  /* width: 30px;
  height: 30px; */
}
.rewardedRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.rewarding {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 24px auto;
}

.btn-brm-bg,
.btn-brm-bg:hover,
.btn-brm-bg:focus,
.btn-brm-bg:active {
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  background-color: transparent;
  background-image: url("https://bilodev.com/wp-content/uploads/2024/07/btn.png");
  color: white;
  width: 80%;
  padding: 24px;
  border: none;
  display: flex;
  min-height: 86px;
  align-items: center;
  justify-content: center;
}

.claim-btn {
  background: linear-gradient(
    88.97deg,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );

  color: white;
  border-radius: 8px;
  padding: 0;
  border: none;
}

.btn-brm-bg:focus {
  outline: none; /* Убирает стандартный фокусный контур */
}

.footer-container p {
  font-size: 10px;
}

p,
span {
  margin: 12px;
  font-weight: 600;
}
.paragraph {
  font-size: 12px;
}

h1 {
  font-size: 22px;
}

.airdrop {
  position: absolute;
  top: 50px;
  right: 30px;
}

.airdrop p {
  color: white;
  font-size: 8px;
}

.airdrop-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #000;
  z-index: 3;
}
.airdrop-container img {
  position: absolute;
  right: 0;
  top: 0;
}
.ant-table-thead {
  font-size: 12px;
}
.rewarded-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 16px;
}

.rewarded-inner {
  color: white;
  border-radius: 16px;
  border: solid 1px #74b9ec;
  padding: 16px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active-rewarded {
  background: linear-gradient(46.25deg, #74b9ec 2.11%, #8d22e1 87.15%);
  border: none;
}

.next-rewarded {
  border: 2px dashed #74b9ec;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* New styles for the claim button */
.ant-btn-primary {
  background: linear-gradient(
    88.97deg,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );

  color: white;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: linear-gradient(
    88.97deg,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );

  color: white;
}

.ant-btn-primary:active {
  background: linear-gradient(
    88.97deg,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );

  color: white;
}

thead.ant-table-thead tr th.ant-table-cell {
  background-color: #1a1a1a;
  border: none;
  font-size: 12px;
  color: white;
}
.rewarded-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%; /* Максимально растягиваем контейнер */
  padding: 16px;
  box-sizing: border-box;
  overflow-y: auto; /* Добавляем вертикальную прокрутку */
}

.rewarded-inner {
  color: white;
  border-radius: 16px;
  border: solid 1px #74b9ec;
  padding: 16px;
  text-align: center;
  box-sizing: border-box;
  min-height: 200px; /* Устанавливаем минимальную высоту */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Устанавливаем пространство между элементами */
}

.active-rewarded {
  background: linear-gradient(46.25deg, #74b9ec 2.11%, #8d22e1 87.15%);
  border: none;
}

.next-rewarded {
  border: 2px dashed #74b9ec;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* New styles for the claim button */
.claim-btn,
.claim-btn:hover,
.claim-btn:focus,
.claim-btn:active {
  border-radius: 12px;
  background-size: cover;
  background-position: center;
  background-color: transparent;
  background: linear-gradient(
    88.97deg,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Уменьшаем размер шрифта для адаптации */
}

.claim-btn:focus {
  outline: none; /* Убирает стандартный фокусный контур */
}

.rewarded-inner p {
  margin: 0;
}
@media (max-width: 1024px) {
  .rewarded-inner {
    min-height: 150px; /* Уменьшаем минимальную высоту контейнера */
    padding: 12px; /* Уменьшаем padding */
  }

  .rewarded-inner h3 {
    font-size: 16px; /* Уменьшаем размер шрифта заголовка */
  }

  .rewarded-inner p {
    font-size: 14px; /* Уменьшаем размер шрифта текста */
  }

  .claim-btn {
    padding: 10px; /* Еще больше уменьшаем padding для адаптации */
    font-size: 12px; /* Еще больше уменьшаем размер шрифта для адаптации */
  }
}

@media (max-width: 768px) {
  .rewarded-inner {
    min-height: 120px; /* Еще больше уменьшаем минимальную высоту контейнера */
    padding: 10px; /* Еще больше уменьшаем padding */
  }

  .rewarded-inner h3 {
    font-size: 14px; /* Еще больше уменьшаем размер шрифта заголовка */
  }

  .rewarded-inner p {
    font-size: 12px; /* Еще больше уменьшаем размер шрифта текста */
  }

  .claim-btn {
    padding: 10px; /* Еще больше уменьшаем padding для адаптации */
    font-size: 10px; /* Еще больше уменьшаем размер шрифта для адаптации */
  }
}

@media (max-width: 480px) {
  .rewarded-inner {
    min-height: 100px; /* Еще больше уменьшаем минимальную высоту контейнера */
    padding: 8px; /* Еще больше уменьшаем padding */
  }

  .rewarded-inner h3 {
    font-size: 12px; /* Еще больше уменьшаем размер шрифта заголовка */
  }

  .rewarded-inner p {
    font-size: 10px; /* Еще больше уменьшаем размер шрифта текста */
  }

  .claim-btn {
    padding: 8px; /* Еще больше уменьшаем padding для адаптации */
    font-size: 8px; /* Еще больше уменьшаем размер шрифта для адаптации */
  }
}

.rewarded-inner p {
  margin: 0;
}

.user-rank {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: linear-gradient(
    88.97deg,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );
  max-width: 600px;
  border-radius: 12px;
}
.user-rank {
  font-size: 12px !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.user-rank .col {
  display: flex;
  flex-direction: column;
}
.img-rank {
  background: #74b9ec;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.first-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-p {
  font-size: 12px;
}
.earn-container {
  margin-top: 24px;
}

.task-item {
  border: 1px solid #ffffff0f;
  width: 84%;
  min-height: 80px;
  border-radius: 100px;
  background-color: #ffffff1a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 20px;
}
.task-item h1,
.task-item p {
  font-size: 12px;
}

.task-item h1 {
  margin-bottom: 6px;
}

.task-item-btn {
  width: 100%;
  background: linear-gradient(88.97deg, #0bc4ff -16.25%, #0366da 109.31%);
  color: white;
  border-radius: 20px;
  height: 30px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
}
.task-item-content {
  width: 80%;
}
.contentearn {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.task-item-btn-active {
  background: linear-gradient(
    88.97deg,
    #ffac0b -16.25%,
    #fc6216 26.71%,
    #ca1184 66.48%,
    #9f03da 109.31%
  );
}
.item-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
